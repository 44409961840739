import { Arrow } from '../../assets/Icons';
import './TableBlock.scss';
// import View from '../../assets/Images/view.png'
import Book from '../../assets/Images/book.png';
import Comp from '../../assets/Images/computer.png';
import Users from '../../assets/Images/users.png';
import Bino from '../../assets/Images/bino.png';
import { useState } from 'react';

const TableBlock = ({elem}) => {
   const [show, setShow] = useState(false)
   const colorManiya = (ball) => {
      let biger = window.localStorage.getItem('color')
      let percent = ball * 100 / biger
      let one
      let two
      let color
      if (percent >= 50) {
         one = 100 - percent
         two = 100
         color = `rgba(${Math.floor(one)}%, ${Math.floor(two)}%, 0%, 60%)`
      } else if (percent < 50) {
         one = 100
         two = percent * 2
         color = `rgba(${Math.floor(one)}%, ${Math.floor(two)}%, 0%, 60%)`
      }
      return color
   }
   return(
      <div className="tableBlock">
         <div className="tableBlock-inner">
            <h4 className="tableBlock-inner__title">{elem?.nameUz}</h4>
            {/* <h4 className="tableBlock-inner__title">Асакинский район</h4> */}
            <h4 className="tableBlock-inner__title">№ {elem?.shortName}</h4>
            <h4 className={`tableBlock-inner__title`} style={{
               color: colorManiya(+elem?.ballSchool)
            }}>{elem?.ballSchool}</h4>
            <button className={`tableBlock-inner__button ${show ? 'active' : ''}`}
               onClick={() => {
                  setShow(!show)
               }}
            >
               <Arrow />
            </button>
         </div>

         <div className={`tableBlock-box ${show ? 'active' : ''}`}>
            <div className="tableBlock-box-top">
               <h4 className="tableBlock-box-top__icons">
                  <img src={Book} alt="" />
               </h4>
               <h4 className="tableBlock-box-top__icons">
                  <img src={Comp} alt="" />
               </h4>
               <h4 className="tableBlock-box-top__icons">
                  <img src={Users} alt="" />
               </h4>
               <h4 className="tableBlock-box-top__icons">
                  <img src={Bino} alt="" />
               </h4>
            </div>

            <div className="tableBlock-box-center">
               <h4 className={`tableBlock-box-center__icons ${elem?.ball1Inspektor < 120 ? 'red' : elem?.ball1Inspektor < 180 ? 'orange' : elem?.ball1Inspektor < 240 ? 'green' : 'blue'}`}>
                  {elem?.ball1Inspektor || 0}
               </h4>
               <h4 className={`tableBlock-box-center__icons ${elem?.ball2Inspektor < 80 ? 'red' : elem?.ball2Inspektor < 120 ? 'orange' : elem?.ball2Inspektor < 160 ? 'green' : 'blue'}`}>
                  {elem?.ball2Inspektor || 0}
               </h4>
               <h4 className={`tableBlock-box-center__icons ${elem?.ball3Inspektor < 80 ? 'red' : elem?.ball3Inspektor < 120 ? 'orange' : elem?.ball3Inspektor < 160 ? 'green' : 'blue'}`}>
                  {elem?.ball3Inspektor || 0}
               </h4>
               <h4 className={`tableBlock-box-center__icons ${elem?.ball4Inspektor < 120 ? 'red' : elem?.ball4Inspektor < 180 ? 'orange' : elem?.ball4Inspektor < 240 ? 'green' : 'blue'}`}>
                  {elem?.ball4Inspektor || 0}
               </h4>
            </div>
         </div>
      </div>
   )
}

export default TableBlock