import './FaqAcordion.scss';
import Question from '../../assets/Icons/question.png'
import Arrow from '../../assets/Icons/arrow.png'
import Question1 from '../../assets/Icons/question1.png'
import Arrow1 from '../../assets/Icons/arrow1.png'
import { useState } from 'react';


const FaqAcordion = () => {
   const [active, setActive] = useState(false)
   return (
      <div className={`faqAcordion ${active ? 'active' : ''}`} onClick={() => setActive(!active)}>
         <div className="faqAcordion-top">
            <div className="faqAcordion-top-inner container">
               <span className="faqAcordion-top-inner__icon">
                  <img src={active ? Question : Question1} alt="" />
               </span>
               <h3 className="faqAcordion-top-inner__title">
                  Как получить сертификат современной школы?
               </h3>
               <span className="faqAcordion-top-inner__arrow">
                  <img src={active ? Arrow : Arrow1} alt="" />
               </span>
            </div>
         </div>

         {
            active ?

               <div className="faqAcordion-center container">
                  <p className="faqAcordion-center__text">
                     Для того чтобы получить сертификат, школе необходимо пройти ряд процедур связанных с проверкой. Независимая комиссия во главе с МНО будет составлять рейтинги. Если школа набирает нужное количество баллов, то ей выдается сертификат “Современной школы”
                  </p>
               </div> : <></>
         }
      </div>
   )
}

export default FaqAcordion