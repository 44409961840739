import axios from "axios"
import { useEffect, useState } from "react"
import Loader from "../../components/Loader/Loader"
import SearchInput from "../../components/SearchInput/SearchInput"
import SelectInput from "../../components/SelectInput/SelectInput"
import { useLanguage, useSchools } from "../../context/DataProvider"
import { Axios } from "../../services"
import MyTable from "../MyTable/MyTable"


const HomeTable = () => {
   const [viloyatlar, setViloyatlar] = useState([])
   const [tumanlar, setTumanlar] = useState([])
   const [viloyat, setViloyat] = useState('0')
   const [tuman, setTuman] = useState('0')
   const [year, setYear] = useState('2021')
   const [setSchools] = useSchools(true)
   const [loading, setLoading] = useState(false)
   const [lang] = useLanguage()
   const [reset, setReset] = useState(false)
   const [search, setSearch] = useState('')

   const years = [
      {
         id: '2014',
         name_uz: '2014'
      },
      {
         id: '2015',
         name_uz: '2015'
      },
      {
         id: '2016',
         name_uz: '2016'
      },
      {
         id: '2017',
         name_uz: '2017'
      },
      {
         id: '2018',
         name_uz: '2018'
      },
      {
         id: '2019',
         name_uz: '2019'
      },
      {
         id: '2020',
         name_uz: '2020'
      },
      {
         id: '2021',
         name_uz: '2021'
      }
   ]


   




   useEffect(() => {
      let isCancelled = false;
      setLoading(true)
      async function getQuestions() {
         try {
            const { data } = await Axios.get(`/getdistricts/?id=${viloyat}`)
            if (data) {
               setTumanlar(data)
               setLoading(false)
               isCancelled = true
            }
         } catch (err) {
            console.log(err)
         }
      }

      !isCancelled && getQuestions()
   }, [viloyat])


   useEffect(() => {
      let isCancelled = false;
      setLoading(true)
      async function getQuestions() {
         try {
            const { data } = await Axios.get(`/getregions`)
            if (data) {
               setViloyatlar(data)
               setLoading(false)
               isCancelled = true
            }
         } catch (err) {
            console.log(err)
         }
      }

      !isCancelled && getQuestions()
   }, [])

   useEffect(() => {
      let isCancelled = false;
      setLoading(true)
      async function getQuestions() {
         try {
            const { data } = await axios.get(`https://zm.maktab.uz/AdminAPI/RatedSchools?year=${year}&page=1&RegionId=${viloyat}&DistrictId=${tuman}&SchoolNumber=${search}&schooltype=0`, {
               auth: {
                  username: 'zmuser',
                  password: 'zm@#1234'
               }
            })
            if (data) {
               setSchools(data)
               console.log(data)
               isCancelled = true
               setLoading(false)
            }
         } catch (err) {
            console.log(err)
         }
      }

      !isCancelled && getQuestions()
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [search, viloyat, tuman])







   return (
      <div className="home-scroll container" style={{ position: 'relative' }}>
         {/* FILTER  SCHOOLS */}
         <div className="home-section2-top">
            <SearchInput setInfo={setSearch} />

            <SelectInput info={years} setInfo={setYear} title={lang?.home?.table?.year} defaultV={`2021`} />
            <SelectInput  info={viloyatlar} setInfo={setViloyat} title={lang?.home?.table?.viloyat} />
            <SelectInput  info={tumanlar} setInfo={setTuman} title={lang?.home?.table?.tuman}  />
            <button className="home-header__button"
               onClick={() => {
                  setReset(!reset)
               }}
            >
               {lang.header.filter0}
            </button>
            {/* <SelectInput title="Показать по: 5" /> */}
         </div>

         {/* LOADER  */}
         {
            loading ? <Loader /> : <></>
         }

         {/* TABLE SCHOOLS  */}
         <MyTable />
      </div>
   )
}

export default HomeTable