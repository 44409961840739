import './SelectInput.scss';

const SelectInput = ({ info, setInfo, title, defaultV }) => {
   return (
      <select className="selectInput"
         onChange={(e) => {
            setInfo(e.target.value)
         }}
         defaultValue={defaultV && defaultV}
      >
         <>
            {
               !defaultV && <option disabled  value="">{title}</option>
            }
         </>
         <>
            {
               info && info.map(elem => (
                  <option value={elem?.id} key={elem?.id}
                     
                  >{elem?.name_uz || elem?.nameUz}</option>
               ))
            }
         </>
      </select>
   )
}

export default SelectInput