import './assets/styles/main.scss';
import Footer from './containers/Footer/Footer';
import Header from './containers/Header/Header';
import ContactPage from './pages/ContactPage/ContactPage';
import Faq from './pages/Faq/Faq';
import Home from './pages/Home/Home';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import SchoolCreteries from './pages/SchoolCreteries/SchoolCreteries';
import SchoolsReyting from './pages/SchoolsReyting/SchoolsReyting';
import News from './pages/News/News';







function App() {
 

  return (
    <Router>
      <div className="App">
        {/* HEADER  */}
        <div className="app-header">
          <Header />
        </div>

        <div className="app-body">
          <Switch>
            {/* HOME PAGE */}
            <Route exact path="/" component={Home}/>
            {/* CRETERIES PAGE */}
            <Route exact path="/creteries" component={SchoolCreteries}/>
            {/* REYTING PAGE */}
            <Route exact path="/reyting" component={SchoolsReyting}/>
            {/* FAQ PAGE */}
            <Route exact path="/faq" component={Faq}/>
            {/* CONTACT PAGE */}
            <Route exact path="/contact" component={ContactPage}/>
            <Route exact path="/news" component={News}/>
          </Switch>
        </div>

          {/* FOOTER */}
        <div className="app-footer">
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
