import './Footer.scss';
import Logo from '../../assets/Images/logo3.svg'
import { FaceBook, Instagram, VK } from '../../assets/Icons';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/DataProvider';

const Footer = () => {
   const [lang] = useLanguage()
   return(
      <div className="footer">
         <div className="footer-inner container">
            {/* ADRESS  */}
            <div className="footer-inner-items">
               <h3 className="footer-inner-items__link">{lang?.footer?.adress}:</h3>
               <h3 className="footer-inner-items__link subtitle">
                  {lang?.footer?.adressTitle}
               </h3>
            </div>
            
            {/* LOGO  */}
            <div className="footer-inner-items">
               <img src={Logo} alt="" />
            </div>

            {/* LINKS  */}
            <div className="footer-inner-items">
               <Link to="/" className="footer-inner-items__link">{lang?.footer?.link1}</Link>
               <Link to="/" className="footer-inner-items__link">{lang?.footer?.link2}</Link>
               <Link to="/" className="footer-inner-items__link">{lang?.footer?.link3}</Link>
               <Link to="/" className="footer-inner-items__link">{lang?.footer?.link4}</Link>
            </div>

            {/* LINKS  */}
            <div className="footer-inner-items">
               <Link to="/" className="footer-inner-items__link">{lang?.footer?.link5}</Link>
               <Link to="/" className="footer-inner-items__link max">{lang?.footer?.link6}</Link>
               <h4 href="" className="footer-inner-items__link">{lang?.footer?.link7}</h4>


               {/* SOCIAL  */}
               <div className="footer-inner-items-box">
                  <Link to="/" className="footer-inner-items-box__link">
                     <FaceBook/>
                  </Link>
                  <Link to="/" className="footer-inner-items-box__link">
                     <Instagram/>
                  </Link>
                  <Link to="/" className="footer-inner-items-box__link">
                     <VK/>
                  </Link>
               </div>

            </div>
         </div>
      </div>
   )
}

export default Footer