import Creteries from '../../containers/Creteries/Creteries';
import { useLanguage } from '../../context/DataProvider';
import './SchoolCreteries.scss';

const SchoolCreteries = () => {
   const [lang] = useLanguage()
   return(
      <div className="schoolCreteries">
         {/* HEADER CRETERIES PAGE */}
         <div className="schoolCreteries-header container">
            <h2 className="schoolCreteries-header__title">
               {lang?.header?.creteries}
            </h2>

            <div className="schoolCreteries-header-map">
               <span>{lang?.header?.home}</span> {`>`} <span>{lang?.header?.creteries}</span>
            </div>
         </div>

         {/* CRETERIES */}
         <div className="schoolCreteries-body container">
            <Creteries />
         </div>
      </div>
   )
}

export default SchoolCreteries