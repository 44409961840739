import './IconBlock.scss';

const IconBlock = ({img1, title1}) => {
   return(
      <div className="iconBlock">
         <div className="iconBlock-img">
            <img  src={img1} alt="" />
         </div>

         <h3 className="iconBlock__title">
            {title1}
         </h3>
      </div>
   )
}

export default IconBlock