import FaqAcordion from '../../components/FaqAcordion/FaqAcordion';
import { useLanguage } from '../../context/DataProvider';
import './Faq.scss';

const Faq = () => {
   const [lang] = useLanguage()
   return (
      <div className="faq">
         {/* HEADER FAQ PAGE  */}
         <div className="faq-header container">
            <h2 className="faq-header__title">
               {lang?.header?.faq}
            </h2>

            <div className="faq-header-map">
               <span>{lang?.header?.home}</span> {`>`} <span>{lang?.header?.faq}</span>
            </div>
         </div>


         {/* FAQ ACORDIONS  */}
         <div className="faq-body">
            <FaqAcordion/>
            <FaqAcordion/>
            <FaqAcordion/>
            <FaqAcordion/>
         </div>
      </div>
   )
}

export default Faq