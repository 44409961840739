const ru = {
   language: 'ru',
   header: {
      home: 'ГЛАВНАЯ',
      creteries: 'КРИТЕРИИ ОТБОРА',
      reytingSchool: 'РЕЙТИНГ ШКОЛ',
      faq: 'FAQ',
      contact: 'КОНТАКТЫ',
      ru: "Ру",
      uz: "Уз",
      en: "Ан",
      show: 'видеть',
      enter: 'ВОЙТИ',
      filter0: 'Сброс'
   },
   home: {
      titleSec1: "Современная школа - школа доверия, толерантности, поддержки индивидуального развития детей, школа формирования здорового безопасного образа жизни.",
      knowAll: "УЗНАТЬ БОЛЬШЕ",
      table: {
         title: 'Название',
         numberSchool: 'Номер школы',
         ball: 'Общий балл',
         viloyat: 'Регион',
         tuman: 'Район/Город'
      }
   }, ReytingTable: {
      col1: {
         title: 'Главная Информация',
         row1: "Регион",
         row2: 'Район/Город',
         row3: 'Название школы',
         row4: 'Тип школы',
         row5: 'Директор',
         row6: 'Год постройки школы',
         row7: 'Вместимость школы',
         row8: 'Контактный телефон',
         row9: 'Адрес',
      },
      col2: {
         title: 'Успеваемость учащихся и внеклассное обучение',
         row1: "Всего студентов",
         row2: 'Количество выпускников',
         row3: 'Итоги республиканских научных олимпиад',
         row4: 'Результаты международных научных олимпиад',
         row5: 'Итоги олимпиад и конкурсов в республике',
         row6: 'Результаты международных конкурсов и конкурсов',
         row7: 'Внедрение разнообразной учебной программы',
         row8: 'Круги',
         row9: 'Посещаемость',
      },
      col3: {
         title: 'Управление школой и статус ИКТ',
         row1: "3-летняя программа развития школы",
         row2: 'Годовой план работы директора',
         row3: 'Годовой план работы заместителя директора',
         row4: 'Практические результаты планов',
         row5: 'Количество используемых компьютеров',
         row6: 'Компьютеры, подключенные к локальной сети',
         row7: 'Количество новых компьютеров, полученных за последние 3 года',
         row8: 'скорость интернета',
         row9: 'Wi-Fi сеть',
         row10: 'Страницы социальных сетей',
      },
      col4: {
         title: 'Преподавательский состав',
         row1: "Общее количество учителей",
         row2: 'Количество учителей с высшим образованием',
         row3: 'Количество учителей со средним специальным образованием',
         row4: 'Количество преподавателей высшей категории',
         row5: 'Количество учителей первой категори',
         row6: 'Количество учителей второй категории',
         row7: 'Количество сертифицированных преподавателей иностранных языков'
      },
      col5: {
         title: 'Материально-техническая база и оборудование',
         row1: "Необходимые строительные конструкции",
         row2: 'Система обогрева',
         row3: 'Против огня',
         row4: 'Питьевая вода',
         row5: 'Туалет',
         row6: 'Количество книг в библиотеке',
         row7: 'Читальный зал',
         row8: 'Общее количество классов',
         row9: 'Частные классы',
      },
   },
   criteries: {
      title: "Этапы оценки по критериям",
      block1: 'Группа инспекторов выезжают в школьное учреждение',
      block2: 'Начинается процесс оценки школы с помощью специализированного планшета',
      block3: 'Инспектор с помощью планшета производит фотофиксацию и прикладывает к каждому пункту критерия',
      block4: 'По окончанию прохождения оценки, выставляется итоговый балл исходя из заполненных ответов',
      block5: 'По окончанию прохождения оценки, выставляется итоговый балл исходя из заполненных ответов',
      block6: 'Школа получает соотвествующий сертификат'
   },
   news: "Новости",
   allNews: 'ВСЕ НОВОСТИ',
   question: 'Почему рейтинг школ важен?',
   contact: {
      title: 'Контакты',
      phone: 'ТЕЛЕФОН',
      fax: 'ФАКС',
      email: 'EMAIL',
      work: 'РЕЖИМ РАБОТЫ',
      adress: 'АДРЕС',
      weInSocial: 'МЫ В СОЦСЕТЯХ'
   },
   footer: {
      adress: "АДРЕС",
      adressTitle: 'Республика Узбекистан, 100187, город Ташкент, улица Буюк ипак йўли, 243',
      link1: 'ДЕЯТЕЛЬНОСТЬ',
      link2: 'КРИТЕРИИ',
      link3: 'РЕЙТИНГ',
      link4: 'ПОМОЩЬ',
      link5: 'КОНТАКТЫ',
      link6: 'ПРАВОВАЯ ИНФОРМАЦИЯ',
      link7: 'НАПИШИТЕ НАМ',
   }
}

export default ru