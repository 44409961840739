import NewBlock from '../../components/NewBlock/NewBlock';
import { useLanguage } from '../../context/DataProvider';
import Prez3 from '../../assets/Images/prez3.jpg'
import Prez4 from '../../assets/Images/prez4.jpg'
import Prez5 from '../../assets/Images/prez5.jpg'
import Img1 from '../../assets/Images/img1.jpg'
import Img2 from '../../assets/Images/img2.jpg'
import Img3 from '../../assets/Images/img3.jpg'
import Img4 from '../../assets/Images/img4.jpg'
import Img5 from '../../assets/Images/img5.jpg'
import Img6 from '../../assets/Images/img6.jpg'
import './News.scss';

const News = () => {
   const [lang] = useLanguage()
   const NewsArray = [{
      img: Prez3,
      title: `Мирзиёев посетил комплекс военных образовательных учреждений СГБ`,
      text: `Именно эти принципы взяты за основу при организации обучения в лицее "Темурбеклар мактаби"`,
      subtitle: "07.01.2022"
   }, {
      img: Prez4,
      title: `В Узбекистане продлили зимние каникулы для студентов`,
      text: `Скидка в 50 процентов на авиа и железнодорожные билеты для студентов будет действовать до 24 января.`,
      subtitle: "07.01.2022"
   },
   {
      img: Prez5,
      title: `Мирзиёев и Эрдоган обсудили ситуацию в Казахстане`,
      text: `6 января состоялся телефонный разговор между Шавкатом Мирзиёевым и президентом Турецкой Республики Реджепом Тайипом Эрдоганом.`,
      subtitle: "06.01.2022"
   },
   {
      img: Img1,
      title: `Ўлчовлар ва мақсаднинг тўғрилиги ҳамда ҳалоллик – Бизнесда барака омиллари мавзусида суҳбат`,
      text: `6 января состоялся телефонный разговор между Шавкатом Мирзиёевым и президентом Турецкой Республики Реджепом Тайипом Эрдоганом.`,
      subtitle: "02.01.2022"
   },
   {
      img: Img2,
      title: `Энг ёш президент мактаби директори билан суҳбат`,
      text: `«Осмондаги болалар» лойиҳасининг 20-сони меҳмони Қашқадарё вилоятидаги Президент мактаби директори ва бир қатор лойиҳалар муаллифи Сардор Содиқов бўлди.`,
      subtitle: "18.01.2022"
   },
   {
      img: Img3,
      title: `«Инсон қадри бу – газ, «свет», сув ва асфалт йўл бўлиши»`,
      text: `Ўзбекистон президенти фаолиятига оид «Менимча» кўрсатувининг навбатдаги сони эфирга узатилди. Хайрулла Нуриддинов муаллифлигидаги лойиҳанинг бу галги сонида биринчи ҳафтадагидан фарқли ўлароқ асосан муаллифнинг нуқтаи назарига урғу берилди.`,
      subtitle: "22.01.2022"
   },
   {
      img: Img4,
      title: `Савдо юришмаяптими? Бирга ҳал қиламиз!`,
      text: `6 января состоялся телефонный разговор между Шавкатом Мирзиёевым и президентом Турецкой Республики Реджепом Тайипом Эрдоганом.`,
      subtitle: "22.01.2022"
   },
   {
      img: Img5,
      title: `«Ҳаммаси бойвачча — машинада юради». Президент жамоат транспорти юзасидан мутасаддиларни танқид қилди`,
      text: `Шавкат Мирзиёев 19 январь куни Тошкент шаҳрида жамоат транспортини ривожлантириш чора-тадбирлари бўйича тақдимот билан танишди. «Менимча» кўрсатувида айтилишича, давлат раҳбари тақдимотдан кўнгли тўлмаганини айтиб, мутасаддиларни танқид қилган.`,
      subtitle: "22.01.2022"
   },
   {
      img: Img6,
      title: `Ўзбекистонда талабаларнинг деярли ярми педагогика соҳасида ўқиши маълум бўлди`,
      text: `Таълим йўналишлари бўйича таҳсил олаётган талабалар сони маълум қилинди. Давлат статистика қўмитаси тегишли хабар билан чиқди.`,
      subtitle: "23.01.2022"
   },

   ]
   return (
      <div className="faq">
         {/* HEADER FAQ PAGE  */}
         <div className="faq-header container">
            <h2 className="faq-header__title">
               {lang?.allNews}
            </h2>

            <div className="faq-header-map">
               <span>{lang?.header?.home}</span> {`>`} <span>{lang?.allNews}</span>
            </div>
         </div>


         {/* FAQ ACORDIONS  */}
         <div className="news-body container">
            {
               NewsArray.map(el => (
                  <NewBlock
                     img={el.img}
                     title={el.title}
                     text={el.text}
                     subtitle={el.subtitle}
                  />
               ))
            }
         </div>
      </div>
   )
}

export default News