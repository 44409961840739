const en = {
   language: 'en',
   header: {
      home: 'HOME',
      creteries: 'SELECTION CRITERIA',
      reytingSchool: 'SCHOOL RATING',
      faq: 'FAQ',
      contact: 'CONTACTS',
      ru: "Ru",
      uz: "Uz",
      en: "En",
      show: 'see',
      enter: 'Login',
      filter0: 'Clear'
   },
   home: {
      titleSec1: "The modern school is a school of trust, tolerance, support for the individual development of children, a school for the formation of a healthy, safe lifestyle.",
      knowAll: "LEARN MORE",
      table: {
         title: 'Name',
         numberSchool: 'School Number',
         ball: 'Overall score',
         viloyat: 'Region',
         tuman: 'District/City'
      }
   }, ReytingTable: {
      col1: {
         title: 'General information',
         row1: "Region",
         row2: 'District',
         row3: 'Name of school',
         row4: 'School type',
         row5: 'Director',
         row6: 'The year the school building was built',
         row7: 'School capacity',
         row8: 'Contact phone',
         row9: 'Address',
      },
      col2: {
         title: 'Student achievement and extracurricular learning',
         row1: "Total students",
         row2: 'Number of graduates',
         row3: 'Results of the Republican Science Olympiads',
         row4: 'Results in International Science Olympiads',
         row5: 'Results of competitions and contests in the republic',
         row6: 'Results of international competitions and contests',
         row7: 'Introduction of a varied curriculum',
         row8: 'Circles',
         row9: 'Attendance',
      },
      col3: {
         title: 'School management and ICT status',
         row1: "3-year school development program",
         row2: 'Annual work plan of the director',
         row3: 'Annual work plan of the Deputy Director',
         row4: 'Practical results of plans',
         row5: 'Number of computers in use',
         row6: 'Computers connected to the local network',
         row7: 'Number of new computers received in the last 3 years',
         row8: 'Internet speed',
         row9: 'Wi-Fi network',
         row10: 'Social networking pages',
      },
      col4: {
         title: 'Teaching staff',
         row1: "Total number of teachers",
         row2: 'Number of teachers with higher education',
         row3: 'Number of teachers with secondary special education',
         row4: 'Number of teachers of the highest category',
         row5: 'Number of teachers of the first category',
         row6: 'Number of teachers of the second category',
         row7: 'Number of certified foreign language teachers'
      },
      col5: {
         title: 'Material and technical base and equipment',
         row1: "Necessary building structures",
         row2: 'Heating system',
         row3: 'Against fire',
         row4: 'Drinking water',
         row5: 'Toilet',
         row6: 'Number of books in the library',
         row7: 'Reading room',
         row8: 'Total number of classrooms',
         row9: 'Private classrooms',
      },
   },
   criteries: {
      title: "Stages of assessment by criteria",
      block1: 'A group of inspectors leave for the school',
      block2: 'The process of assessing the school begins with a specialized tablet',
      block3: 'The inspector takes photographs using a tablet and applies criteria to each item',
      block4: 'At the end of the assessment, a final score is given based on the completed answers',
      block5: 'At the end of the assessment, a final score is given based on the completed answers',
      block6: 'The school receives the appropriate certificate'
   },
   news: "News",
   allNews: 'ALL NEWS',
   question: 'Why is school ranking important?',
   contact: {
      title: 'Contacts',
      phone: 'TELEPHONE',
      fax: 'FAX',
      email: 'EMAIL',
      work: 'OPERATING MODE',
      adress: 'THE ADDRESS',
      weInSocial: 'WE ARE IN SOCIAL NETWORKS'
   },
   footer: {
      adress: "ADDRESS",
      adressTitle: 'Republic of Uzbekistan, 100187, Tashkent city, Buyuk ipak yuli street, 243',
      link1: 'ACTIVITY',
      link2: 'CRITERIA',
      link3: 'RATING',
      link4: 'HELP',
      link5: 'CONTACTS',
      link6: 'LEGAL INFORMATION',
      link7: 'Contact us',
   }
}

export default en