import axios from 'axios';
import { useEffect, useState } from 'react/cjs/react.development';
import Loader from '../../components/Loader/Loader';
import SelectInput from '../../components/SelectInput/SelectInput';
import ReytingTable from '../../containers/ReytingTable/ReytingTable';
import { useLanguage } from '../../context/DataProvider';
import { Axios } from '../../services';
import './SchoolsReyting.scss';

const SchoolsReyting = () => {
   const [lang] = useLanguage()
   const [loading, setLoading] = useState(false)
   const [viloyatData, setViloyatData] = useState([])
   const [tumanData, setTumanData] = useState([])
   const [schoolData, setSchoolData] = useState([])
   const [school, setSchools] = useState('')
   const [viloyat, setViloyat] = useState('')
   const [tuman, setTuman] = useState('')
   const [data, setData] = useState([])
   const [reset, setReset] = useState(false)


   const getRegions = async (id) => {
      setViloyat(id)
      const { data } = await Axios.get(`/getdistricts/?id=${id}`)
      if (data) {
         setTumanData(data)
         getSchools(data[0]?.id)
      }
   }

   const getSchools = async (id) => {
      setTuman(id)
      const { data } = await axios.get(` https://zm.maktab.uz/getapi/getschools/?id=${id}`, {
         auth: {
            username: 'zmuser',
            password: 'zm@#1234'
         }
      })
      if (data) {
         setSchoolData(data)
         setSchools(data[0]?.id)
      }
   }





   useEffect(() => {
      let isCancelled = false;
      setLoading(true)
      async function getQuestions() {
         try {
            const { data } = await axios.get(`https://zm.maktab.uz/AdminAPI/GetSchoolInfo?year=2021&school_id=${school}`, {
               auth: {
                  username: 'zmuser',
                  password: 'zm@#1234'
               }
            })
            if (data) {
               setData(data)  
               setLoading(false)
               isCancelled = true
            }
         } catch (err) {
            console.log(err)
         }
      }

      !isCancelled && getQuestions()
   }, [school])

   useEffect(() => {
      let isCancelled = false;
      setLoading(true)
      async function getQuestions() {
         try {
            const { data } = await Axios.get(`/getregions`)
            if (data) {
               setViloyatData(data)
               if (data?.length > 0) {
                  getRegions(data[0]?.id)
                  setViloyat(data[0]?.id)
                  setReset(true)
               }
               isCancelled = true
            }
         } catch (err) {
            console.log(err)
         }
      }

      !isCancelled && getQuestions()
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reset])





   return (
      <div className="schoolsReyting container">
         {/* HEADER REYTING SCHOOLS PAGE  */}
         <div className="schoolsReyting-header container">
            <h2 className="schoolsReyting-header__title">
               {lang?.header?.reytingSchool}
            </h2>

            <div className="schoolsReyting-header-map">
               <span>{lang?.header?.home}</span> {`>`} <span>{lang?.header?.reytingSchool}</span>
            </div>
         </div>

         {/* BODY REYTING SCHOOLS PAGE  */}
         <div className="schoolsReyting-body container">
            <div className="schoolsReyting-body-top">
               {
               reset ? <>
                     <SelectInput info={viloyatData} setInfo={getRegions} defaultV={viloyat} />
                     <SelectInput info={tumanData} setInfo={getSchools} defaultV={tuman} />
                     <SelectInput info={schoolData} setInfo={setSchools} defaultV={school} />
                     <button className="home-header__button"
                        onClick={() => {
                           setReset(!reset)
                        }}
                     >
                        {lang.header.filter0}
                     </button>
               </> : <div style={{visibility: 'hidden'}}>

                        <SelectInput info={viloyatData} setInfo={getRegions} defaultV={viloyat} />
                        <SelectInput info={tumanData} setInfo={getSchools} defaultV={tuman} />
                        <SelectInput info={schoolData} setInfo={setSchools} defaultV={school} />
                        <button className="home-header__button"
                           onClick={() => {
                              setReset(!reset)
                           }}
                        >
                           {lang.header.filter0}
                        </button>
               </div>
               }
            </div>
            <div className="schoolsReyting-body-center">
               {
                  loading && <Loader />
               }
               <ReytingTable data={data && data}/>
            </div>
         </div>

      </div>
   )
}

export default SchoolsReyting