import './Header.scss';
import Logo from '../../assets/Images/logo2.svg'
import View from '../../assets/Images/view.png'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useLanguage } from '../../context/DataProvider';
import uz from '../../lang/translate-uz';
import ru from '../../lang/translate-ru';
import en from '../../lang/translate-en';
import {
   SettingsModal, defaultSetBodyClass,
   defaultGetSettings,
} from '@n3/react-vision-panel';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
cookies.set('n3__wcag__color', 'blue', { path: '/' });
cookies.set('n3__wcag__kerning', 'average', { path: '/' });
cookies.set('n3__wcag__fontsize', 'average', { path: '/' });


const Header = () => {
   const [burger, setBurger] = useState(false)
   const [lang, setLang] = useLanguage()
   const [show, setShow] = useState(false)
   let settings = defaultGetSettings();

   const closeAll = () => {
      setShow(false)
   }


   Object.keys(settings)
      .forEach((property) => {
         defaultSetBodyClass(property, settings[property]);
      });


   return (
      <div className="header container">
         <SettingsModal
            show={show}
            onHide={closeAll}
         />
         {/* LOGO  */}
         <div className="header-left">
            <Link to={`/`}>
               <img src={Logo} alt="" />
            </Link>
         </div>

         {/* LINKS BOX  */}
         <div className={`header-right ${burger ? 'active' : ''}`}>
            <div className="header-right-links">
               <Link to="/" className="header-right-links__link" lang={lang?.language} aria-label={lang?.header?.home}
                  onClick={() => setBurger(false)}
               >{lang?.header?.home}</Link>
               <Link to="/creteries" className="header-right-links__link" lang={lang?.language} aria-label={lang?.header?.creteries}
                  onClick={() => setBurger(false)}
               >{lang?.header?.creteries}</Link>
               <Link to="/reyting" className="header-right-links__link" lang={lang?.language} aria-label={lang?.header?.reytingSchool}
                  onClick={() => setBurger(false)}
               >{lang?.header?.reytingSchool}</Link>
               <Link to="/faq" className="header-right-links__link" lang={lang?.language} aria-label={lang?.header?.faq}
                  onClick={() => setBurger(false)}
               >{lang?.header?.faq}</Link>
               <Link to="/contact" className="header-right-links__link" lang={lang?.language} aria-label={lang?.header?.contact}
                  onClick={() => setBurger(false)}
               >{lang?.header?.contact}</Link>
            </div>


            {/* LANGUAGE SELECT  */}
            <select name="" id="" className="header-right__select" lang={lang?.language} aria-label="язык"
               onChange={(e) => {
                  if (e.target.value === '1') {
                     setLang(ru)
                  } else if (e.target.value === '2') {
                     setLang(uz)
                  } else {
                     setLang(en)
                  }
               }}
            >
               <option value="1">{lang?.header?.ru}</option>
               <option value="2">{lang?.header?.uz}</option>
               <option value="3">{lang?.header?.en}</option>
            </select>


            {/* SHOW BUTTON  */}
            <button className="header-right__view" lang={lang?.language} aria-label={lang?.header?.show}
               onClick={() => {
                  setShow(true)
               }}
            >
               <img src={View} alt="" />
            </button>

            {/* LOGIN BUTTON  */}
            <button className="header-right__button" lang={lang?.language} aria-label={lang?.header?.enter}
               onClick={() => {
                  window.location.href = 'https://zm.maktab.uz/Account/Login?ReturnUrl=%2F'
               }}
            >
               {lang?.header?.enter}
            </button>
         </div>

         {/* BURGER IN MOBILE  */}
         <div className={`burger ${burger ? 'active' : ''}`}
            onClick={() => setBurger(!burger)}
         >
            <span></span>
         </div>
      </div>
   )
}

export default Header