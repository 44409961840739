import './Contact.scss';
import { Email, FaceBook, Fax, Instagram, Location, Phone, Time, VK } from '../../assets/Icons';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/DataProvider';

const Contact = () => {
   const [lang] = useLanguage()
   return (
      <div className="contact">

         <div className="contact-inner container">
            <div className="contact-inner-left">
               {/* PHONE  */}
               <div className="contact-inner-left-row">
                  <div className="contact-inner-left-row-icon"><Phone /></div>
                  <div className="contact-inner-left-row-info">
                     <h3 className="contact-inner-left-row-info__title">{lang?.contact?.phone}</h3>
                     <Link to='/' className="contact-inner-left-row-info__subtitle">+998 99 797-88-89</Link>
                     <Link to="/" className="contact-inner-left-row-info__subtitle">+998 90 972-58-82</Link>
                  </div>
               </div>
               
               {/* FAX  */}
               <div className="contact-inner-left-row">
                  <div className="contact-inner-left-row-icon"><Fax /></div>
                  <div className="contact-inner-left-row-info">
                     <h3 className="contact-inner-left-row-info__title">{lang?.contact?.fax}</h3>
                     <Link to="/" className="contact-inner-left-row-info__subtitle">+998 71 265-90-92</Link>
                  </div>
               </div>

               {/* EMAIL  */}
               <div className="contact-inner-left-row">
                  <div className="contact-inner-left-row-icon"><Email /></div>
                  <div className="contact-inner-left-row-info">
                     <h3 className="contact-inner-left-row-info__title">{lang?.contact?.email}</h3>
                     <Link to="/" className="contact-inner-left-row-info__subtitle">info@zamonaviymaktab.uz</Link>
                  </div>
               </div>

               {/* WORK TIME  */}
               <div className="contact-inner-left-row">
                  <div className="contact-inner-left-row-icon"><Time /></div>
                  <div className="contact-inner-left-row-info">
                     <h3 className="contact-inner-left-row-info__title">{lang?.contact?.work}</h3>
                     <Link to="/" className="contact-inner-left-row-info__subtitle">Пн-Пт с 9:00 до 18:00</Link>
                  </div>
               </div>

               {/* LOCATION  */}
               <div className="contact-inner-left-row">
                  <div className="contact-inner-left-row-icon"><Location /></div>
                  <div className="contact-inner-left-row-info">
                     <h3 className="contact-inner-left-row-info__title">{lang?.contact?.adress}</h3>
                     <Link to="/" className="contact-inner-left-row-info__subtitle">Республика Узбекистан,
                        100187, город Ташкент,
                        улица Буюк ипак йўли, 243</Link>

                     <h3 className="contact-inner-left-row-info__title social">{lang?.contact?.weInSocial}</h3>
               {/* SOCIALS  */}
                     <div className="contact-inner-left-row-info-box">
                        <Link to="/"><FaceBook /></Link>
                        <Link to="/"><Instagram /></Link>
                        <Link to="/"><VK /></Link>
                     </div>
                  </div>
               </div>
            </div>
            <div className="contact-inner-right">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d95838.73051929829!2d69.19618559999999!3d41.35321599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sru!2s!4v1640229918239!5m2!1sru!2s" width="600" height="450" style={{ border: 0 }} title='map'/>
            </div>
         </div>
      </div>
   )
}

export default Contact