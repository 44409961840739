import React, { createContext, useContext, useState } from 'react'
import ru from '../lang/translate-ru';
const Context = createContext();


const DataProvider = ({ children }) => {

   const [schools, setSchools] = useState("")
   const [language, setLanguage] = useState(ru)

   const value = {
      schools,
      setSchools,
      language,
      setLanguage
   }

   return (
      <Context.Provider value={value}>
         <Context.Consumer>
            {
               () => children
            }
         </Context.Consumer>
      </Context.Provider>
   )
}

const useSchools = (setterOnly) => {
   const { schools, setSchools } = useContext(Context)
   return setterOnly ? [setSchools] : [schools, setSchools]
}

const useLanguage = (setterOnly) => {
   const { language, setLanguage } = useContext(Context)
   return setterOnly ? [setLanguage] : [language, setLanguage]
}


export {
   useSchools,
   DataProvider,
   useLanguage
}