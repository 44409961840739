import axios from "axios"

const api = 'https://zm.maktab.uz/getapi'

const Axios = axios.create({
   baseURL: api,
   headers: {
      'Access-Control-Allow-Origin': '*'
   },
   auth: {
      username: 'zmuser',
      password: 'zm@#1234'
   }
})


export {
   Axios
}