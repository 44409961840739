const uz = {
   language: 'uz',
   header: {
      home: 'ASOSIY',
      creteries: 'TANLASH MEZONLARI',
      reytingSchool: 'MAKTAB REYTINGI',
      faq: 'FAQ',
      contact: 'ALOQA',
      ru: "Ru",
      uz: "Uz",
      en: "En",
      show: 'ko`rish',
      enter: 'KIRISH',
      filter0: 'Tozalash'
   },
   home: {
      titleSec1: "Zamonaviy maktab ishonch, bag'rikenglik, bolalarning individual rivojlanishini qo'llab-quvvatlash maktabi, sog'lom, xavfsiz turmush tarzini shakllantirish maktabidir.",
      knowAll: "Ko'proq bilish",
      table: {
         title: 'Nomi',
         numberSchool: 'Maktab raqami',
         ball: 'Umumiy ball',
         viloyat: 'Viloyat',
         tuman: 'Tuman/shahar'
      }
   },
   ReytingTable: {
      col1: {
         title: 'Umumiy malumotlar',
         row1: "Viloyat",
         row2: 'Tuman',
         row3: 'Maktab nomi',
         row4: 'Maktab turi',
         row5: 'Direktor',
         row6: 'Maktab binosi qurilgan yil',
         row7: 'Maktab quvvati',
         row8: 'Aloqa telefoni',
         row9: 'Manzil',
      },
      col2: {
         title: 'O`quvchilar yutuqlari hamda darsdan tashqari talim olish',
         row1: "Jami o`quvchilar",
         row2: 'Bitiruvchilar soni',
         row3: 'Respublika fan olimpiadalaridagi natijalar',
         row4: 'Xalqaro fan olimpiadalaridagi natijalar',
         row5: 'Respublikadagi tanlov va musobaqalardagi natijalar',
         row6: 'Xalqaro tanlov va musobaqalardagi natijalar',
         row7: 'Variativ o`quv reja joriy etilganligi',
         row8: 'To`garaklar',
         row9: 'Davomat',
      },
      col3: {
         title: 'Maktab boshqaruvi va AKT xolati',
         row1: "Maktab rivojlantirish bo`yicha 3 yillik dastur",
         row2: 'Direktorning yillik ish rejasi',
         row3: 'Direktor o`rinbosarining yillik ish rejasi',
         row4: 'Rejalarning amaliy natijalari',
         row5: 'Foydalashdagi kompyuterlar soni',
         row6: 'Lokal tarmoqqa ulangan kompyuterlar',
         row7: 'Oxirgi 3 yildagi qabul qilingan yangi kompyuterlar soni',
         row8: 'Internet tezligi',
         row9: 'Wi-Fi tarmog`i',
         row10: 'Ishtimoiy tarmoqdagi sahifalari',
      },
      col4: {
         title: 'O`qituvchilar tarkibi',
         row1: "Jami o`qituvchilar soni",
         row2: 'Oliy malumotli o`qituvchilar soni',
         row3: 'O`rta maxsus malumotli o`qituvchilar soni',
         row4: 'Oliy toifali o`qituvchilar soni',
         row5: 'Birinchi toifali o`qituvchilar soni',
         row6: 'Ikkinchi toifali o`qituvchilar soni',
         row7: 'Tegishli sertifikatga ega chet tili o`qituvchilari soni'
      },
      col5: {
         title: 'Moddiy-texnik bazasi va jixozlanganligi',
         row1: "Zaruriy bino inshootlar",
         row2: 'Isitish tizimi',
         row3: 'Yong`inga qarshi',
         row4: 'Ichimlik suvi',
         row5: 'Xojathona',
         row6: 'Kutubxonadagi kitoblar soni',
         row7: 'O`qish zali',
         row8: 'Jami o`quv sinifxonalari soni',
         row9: 'Maxsus sinifxonalar',
      },
   },
   criteries: {
      title: "Mezon bo'yicha baholash bosqichlari",
      block1: 'Bir guruh inspektorlar maktabga jo`nab ketishdi',
      block2: 'Maktabni baholash jarayoni maxsus planshetdan boshlanadi',
      block3: 'Inspektor planshet yordamida suratga oladi va har bir element uchun mezonlarni qo`llaydi',
      block4: 'Baholash yakunida to`ldirilgan javoblar asosida yakuniy ball qo`yiladi',
      block5: 'Baholash yakunida to`ldirilgan javoblar asosida yakuniy ball qo`yiladi',
      block6: 'Maktab tegishli sertifikat oladi'
   },
   news: "Yangiliklar",
   allNews: 'BARCHA YANGILIKLAR',
   question: 'Nima uchun maktab reytingi muhim?',
   contact: {
      title: 'Kontaktlar',
      phone: 'TELEFON',
      fax: 'FAKS',
      email: 'EMAIL',
      work: 'ISH TARTIBI',
      adress: 'MANZIL',
      weInSocial: 'BIZ IJTIMOIY TARMOQLARDA'
   },
   footer: {
      adress: "MANZIL",
      adressTitle: 'O`zbekiston Respublikasi, 100187, Toshkent shahri, Buyuk ipak yo`li ko`chasi, 243-uy',
      link1: 'FAOLIYAT',
      link2: 'MEZONLAR',
      link3: 'REYTING',
      link4: 'YORDAM',
      link5: 'ALOQA',
      link6: 'HUQUQIY MA`LUMOT',
      link7: 'Biz bilan bog`laning',
   }
}

export default uz