import { useEffect, useState } from 'react';
import TableBlock from '../../components/TableBlock/TableBlock';
import { useLanguage, useSchools } from '../../context/DataProvider';
import './MyTable.scss';

const MyTable = () => {
   const [schools] = useSchools()
   const [arr, setArr] = useState([])
   const [pages, setPages] = useState(1)
   const [active, setActive] = useState(1)
   const [lang] = useLanguage()

   useEffect(() => {
      setActive(1)
      setPages(schools[0]?.totalPageNumber)
      window.localStorage.setItem('color', schools[0]?.ballSchool)
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [schools.length])


   useEffect(() => {
      if(schools.length <= 10){
         setPages(1)
         let array = []
         for (let i =  0 ; i < schools.length - 1; i++) {
            array.push(schools[i])
         }
         setArr(array)
      }
      else if (schools.length > 0) {
         setPages(Math.floor(schools.length / 10))
         let array = []
         for (let i = active === 1 ? 0 : (active - 1) * 10; i < (active * 10); i++) {
            array.push(schools[i])
         }
         setArr(array)
      }
   }, [schools, active])


   return (
      <div className="myTable">
         {/* HEADER TABLE  */}
         <div className="myTable-header">
            <h4 className="myTable-header__title">{lang?.home?.table?.title}</h4>
            {/* <h4 className="myTable-header__title">Район/Город</h4> */}
            <h4 className="myTable-header__title">{lang?.home?.table?.numberSchool}</h4>
            <h4 className="myTable-header__title">{lang?.home?.table?.ball}</h4>
            <h4 className="myTable-header__title">{''}</h4>
         </div>


         {/* BODY TABLE  */}
         <div className="myTable-body">
            {
               arr?.length > 0 && arr.map(elem => (
                  <TableBlock elem={elem} key={elem?.id} />
               ))
            }
         </div>


         {/* PAGINATION TABLE  */}
         <div className="myTable-footer">
            <button
               onClick={() => {
                  setActive((state) => state - 1)
               }}
               disabled={1 === active}
               type='button'
            >
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" /></svg>
            </button>
            <div className="myTable-footer-inner">
               {
                  Array(pages).fill('@').map((el, index) => (
                     <button
                        onClick={() => {
                           setActive(index + 1)
                        }}
                        key={index}
                        type='button'
                        className={active === index + 1 ? 'btnActive' : ''}
                     >{index + 1}</button>
                  ))
               }
            </div>
            <button
               onClick={() => {
                  setActive((state) => state + 1)
               }}
               disabled={pages === active}
               type='button'
            >
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" /></svg>
            </button>
         </div>
      </div>
   )
}

export default MyTable