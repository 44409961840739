import IconBlock from "../../components/IconBlock/IconBlock"
import Group from '../../assets/Images/group.png'
import Check from '../../assets/Images/check.png'
import Camera from '../../assets/Images/camera.png'
import Noot from '../../assets/Images/notebook.png'
import App from '../../assets/Images/approved.png'
import Cer from '../../assets/Images/certificate.png'
import './Creteries.scss'
import { useLanguage } from "../../context/DataProvider"



const Creteries = () => {
   const [lang] = useLanguage()


   // ROW 1 CRETERIS 
   const Info = [
      {
         img: Group,
         title: lang?.criteries?.block1 
      },
      {
         img: Check,
         title: lang?.criteries?.block2
      },
      {
         img: Camera,
         title: lang?.criteries?.block3
      },
   ]
   
   // ROW 2 CRETERIS 
   const Info2 = [
      {
         img: Noot,
         title: lang?.criteries?.block4
      },
      {
         img: App,
         title: lang?.criteries?.block5
      },
      {
         img: Cer,
         title: lang?.criteries?.block6
      },
   ]
   return(
      <div className="creteries">
         <h2 className="home-section3-inner__title">
            {lang?.criteries?.title}
         </h2>

         <div className="home-section3-inner-row">
            {
               Info.map((el, index) => {
                  return <IconBlock
                     img1={el?.img}
                     title1={el?.title}
                     key={index}
                  />
               })
            }
         </div>

         <div className="home-section3-inner-row">
            {
               Info2.map((el, index) => (
                  <IconBlock
                     img1={el?.img}
                     title1={el?.title}
                     key={index}
                  />
               ))
            }
         </div>
      </div>
   )
}

export default Creteries