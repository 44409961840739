import './BigNewBlock.scss';
import Prez1 from '../../assets/Images/prez2.jpg'

const BigNewBlock = () => {
   return(
      <div className="bigNewBlock">
         <div className="bigNewBlock-left">
            <img className='imgShow' src={Prez1} alt="" />
         </div>
         <div className="bigNewBlock-right">
            <h3 className="bigNewBlock-right__title">
               «30 лет мы продавали газ за рубеж, но прибыль не получили»: Шавкат Мирзиёев на заводе GTL
            </h3>
            <p className="bigNewBlock-right__text">
               Открытие завода Uzbekistan GTL в Кашкадарье позволит удовлетворить внутренний спрос страны на нефтепродукты, в частности, на дизельное топливо и керосин, а также на сжиженный природный газ....
            </p>
            <span className="bigNewBlock-right__subtitle">
               25.12.2021
            </span>
         </div>
      </div>
   )
}

export default BigNewBlock