import Contact from '../../containers/Contact/Contact';
import { useLanguage } from '../../context/DataProvider';
import './ContactPage.scss';

const ContactPage  = () => {
   const [lang] = useLanguage()

   return (
      <div className="contactPage">
         {/* HEADER CONTACT PAGE  */}
         <div className="contactPage-header container">
            <h2 className="contactPage-header__title">
               {lang?.header?.contact}
            </h2>

            <div className="contactPage-header-map">
               <span>{lang?.header?.home}</span> {`>`} <span>{lang?.header?.contact}</span>
            </div>
         </div>

         {/* BODY CONTACT PAGE  */}
         <div className="contactPage-body">
            <Contact/>
         </div>
      </div>
   )
}

export default ContactPage

