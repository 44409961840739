import './SearchInput.scss';
import SearchIcon from '../../assets/Images/search.png'

const SearchInput = ({setInfo}) => {
   return(
      <div className="searchInput">
         <img src={SearchIcon} alt="" />

         <input type="search" onInput={(e) => setInfo(e.target.value) } />
      </div>
   )
}

export default SearchInput