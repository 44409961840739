import './NewBlock.scss';

const NewBlock = ({img, text, title, subtitle}) => {
   return(
      <div className="newBlock">
         <div className="newBlock-img">
            <img className='imgShow' src={img} alt="" />
         </div>

         <div className="newBlock-info">
            <h3 className="newBlock-info__title">{title}</h3>
            <p className="newBlock-info__text">{text}</p>
            <span className="newBlock-info__subtitle">{subtitle}</span>
         </div>
      </div>
   )
}

export default NewBlock