import './Home.scss';


import Prez from '../../assets/Images/prez.png'
import Prez3 from '../../assets/Images/prez3.jpg'
import Prez4 from '../../assets/Images/prez4.jpg'
import Prez5 from '../../assets/Images/prez5.jpg'
import BigNewBlock from '../../components/BigNewBlock/BigNewBlock';
import NewBlock from '../../components/NewBlock/NewBlock';
import Contact from '../../containers/Contact/Contact';
import HomeTable from '../../containers/HomeTable/HomeTable';
import Creteries from '../../containers/Creteries/Creteries';
import { useLanguage } from '../../context/DataProvider';
import { Link } from 'react-router-dom';



const Home = () => {
   const [lang] = useLanguage()
   const PrezInfo = {
      img: Prez3,
      title: `Мирзиёев посетил комплекс военных образовательных учреждений СГБ`,
      text: `Именно эти принципы взяты за основу при организации обучения в лицее "Темурбеклар мактаби"`,
      subtitle: "07.01.2022"
   }
   const PrezInfo2 = {
      img: Prez4,
      title: `В Узбекистане продлили зимние каникулы для студентов`,
      text: `Скидка в 50 процентов на авиа и железнодорожные билеты для студентов будет действовать до 24 января.`,
      subtitle: "07.01.2022"
   }

   const PrezInfo3 = {
      img: Prez5,
      title: `Мирзиёев и Эрдоган обсудили ситуацию в Казахстане`,
      text: `6 января состоялся телефонный разговор между Шавкатом Мирзиёевым и президентом Турецкой Республики Реджепом Тайипом Эрдоганом.`,
      subtitle: "06.01.2022"
   }

   

   

   return (
      <div className="home">
         {/* SECTION 1 */}
         <div className="home-section1">
            <div className="home-section1-left">

            </div>
            <div className="home-section1-right ">
               <h2 className="home-section1-right__title">
                  {lang?.home?.titleSec1}
               </h2>

               <button className="home-section1-right__button">
                  {lang?.home?.knowAll}
               </button>
            </div>
         </div>


         {/* TABLE SCHOOLS */}
         <div className="home-section2">
            <HomeTable/>
         </div>

         {/* CRETERIES */}
         <div className="home-section3">
            <div className="home-section3-inner container">
               <Creteries/>
            </div>
         </div>


         {/* QUESTION SECTION */}
         <div className="home-section4">
            <h2 className="home-section4__title">
               {lang?.question}
            </h2>

            <div className="home-section4-inner">
               <div className="home-section4-inner-left">
                  <p className="home-section4-inner-left__title">
                     Рейтинг образовательного учреждения
                     это быстрый и надежный способ оценить
                     престижность, эффективность обучения и
                     перспективы поступления в ВУЗ и
                     трудоустройства после учебы. Ими
                     пользуются родители при выборе школы.
                     Министерства и Управления образования
                     оценивают эффективность затрат при
                     реализации проектов. Директора школ
                     улучшают имидж и  престижность
                     учреждения и стремятся попасть на
                     первые места рейтингов.

                  </p>
               </div>
               <div className="home-section4-inner-right">
                  <img className='imgShow' src={Prez} alt="" />
               </div>
            </div>
         </div>

         <div className="home-section5">
            <h2 className="home-section5__title">{lang?.news}</h2>

            <div className="home-section5-inner container">
               {/* ONE NEWS BLOCK */}
               <div className="home-section5-inner-top">
                  <BigNewBlock />
               </div>


               {/* OTHER NEWS BLOCKS */}
               <div className="home-section5-inner-center">
                  <div className="home-section5-scroll">
                     {/* NEW BLOCK */}
                     <NewBlock
                        img={PrezInfo.img}
                        title={PrezInfo.title}
                        text={PrezInfo.text}
                        subtitle={PrezInfo.subtitle}
                     />
                     <NewBlock
                        img={PrezInfo2.img}
                        title={PrezInfo2.title}
                        text={PrezInfo2.text}
                        subtitle={PrezInfo2.subtitle}
                     />
                     <NewBlock
                        img={PrezInfo3.img}
                        title={PrezInfo3.title}
                        text={PrezInfo3.text}
                        subtitle={PrezInfo3.subtitle}
                     />
                  </div>
               </div>


               {/* BUTTON SHOW ALL */}
               <div className="home-section5-inner-footer">
                  <Link to={`/news`} className="home-section5-inner-footer__button" style={{textDecoration: 'none'}}>
                     {lang?.allNews} {`>`}
                  </Link>
               </div>
            </div>
         </div>


         {/* SECTION CONTACT */}
         <div className="home-section6">
            <h2 className="home-section6__title">
               {lang?.contact?.title}
            </h2>

            <Contact />
         </div>
      </div>
   )
}

export default Home