import { useLanguage } from '../../context/DataProvider';
import './ReytingTable.scss';

const ReytingTable = ({data}) => {
   const [lang] = useLanguage()
   if(data.length > 0){
      console.log(data)
   }
   return(
      <div className="reytingTable">
         {/* REYTING TABLE  */}
         <div className="reytingTable-header">
            <button className="reytingTable-header__button" type='button'>
               {lang.ReytingTable.col1.title}
            </button>

            <button className="reytingTable-header__button" type='button'>
               {lang.ReytingTable.col2.title}
            </button>

            <button className="reytingTable-header__button" type='button'>
               {lang.ReytingTable.col3.title}
            </button>

            <button className="reytingTable-header__button" type='button'>
               {lang.ReytingTable.col4.title}
            </button>

            <button className="reytingTable-header__button" type='button'>
               {lang.ReytingTable.col5.title}
            </button>
         </div>

         <div className="reytingTable-body">
            <div className="reytingTable-body__button" >
               <h4><b>{lang.ReytingTable.col1.row1}:</b> {data[0]?.regionName || ''}</h4>
               <h4><b>{lang.ReytingTable.col1.row2}:</b> {data[0]?.districtName || ''}</h4>
               <h4><b>{lang.ReytingTable.col1.row3}:</b> {data[0]?.schoolName || ''}</h4>
               <h4><b>{lang.ReytingTable.col1.row4}:</b> {data[0]?.schoolType || ''}</h4>
               <h4><b>{lang.ReytingTable.col1.row5}:</b> {data[0]?.director || ''}</h4>
               <h4><b>{lang.ReytingTable.col1.row6}:</b> {data[0]?.buildedYear || ''}</h4>
               <h4><b>{lang.ReytingTable.col1.row7}:</b> {data[0]?.districtName || ''}</h4>
               <h4><b>{lang.ReytingTable.col1.row8}:</b> {data[0]?.phone || ''}</h4>
               <h4><b>{lang.ReytingTable.col1.row9}:</b> {data[0]?.address || ''}</h4>
            </div>

            <div className="reytingTable-body__button" >
               <h4><b>{lang.ReytingTable.col2.row1}:</b> {data[0]?.jamiOquvchi || ''}</h4>
               <h4><b>{lang.ReytingTable.col2.row2}:</b> {data[0]?.bitiruvchi || ''}</h4>
               <h4><b>{lang.ReytingTable.col2.row3}:</b> {data[0]?.respublika1orin || ''} ta 1-o`rin, {data[0]?.respublika2orin || ''} ta 2-chi o`rin {data[0]?.respublika3orin || ''} ta 3-o`rin</h4>
               <h4><b>{lang.ReytingTable.col2.row4}:</b> {data[0]?.xalqaroOltin || ''} ta 1-o`rin, {data[0]?.xalqaroKumush || ''} ta 2-chi o`rin {data[0]?.xalqaroBronza || ''} ta 3-o`rin</h4>
               <h4><b>{lang.ReytingTable.col2.row5}:</b> {data[0]?.musobaqaRespublikaGolib || ''}</h4>
               <h4><b>{lang.ReytingTable.col2.row6}:</b> {data[0]?.musobaqaXalqaroSovrindor || ''}</h4>
               <h4><b>{lang.ReytingTable.col2.row7}:</b> {data[0]?.variativOquvReja || ''}</h4>
               <h4><b>{lang.ReytingTable.col2.row8}:</b> {data[0]?.togaraklar || ''}</h4>
               <h4><b>{lang.ReytingTable.col2.row9}:</b> {data[0]?.davomat || ''}</h4>
            </div>

            <div className="reytingTable-body__button">
               <h4><b>{lang.ReytingTable.col3.row1}:</b> </h4>
               <h4><b>{lang.ReytingTable.col3.row2}:</b> </h4>
               <h4><b>{lang.ReytingTable.col3.row3}:</b> </h4>
               <h4><b>{lang.ReytingTable.col3.row4}:</b> </h4>
               <h4><b>{lang.ReytingTable.col3.row5}:</b> {data[0]?.informatikaSinfKomp || ''}</h4>
               <h4><b>{lang.ReytingTable.col3.row6}:</b> {data[0]?.lokalTarmoqKomp || ''}</h4>
               <h4><b>{lang.ReytingTable.col3.row7}:</b> {data[0]?.oxirgiUchYilKomp || ''}</h4>
               <h4><b>{lang.ReytingTable.col3.row8}:</b> {data[0]?.internetTezligi || ''}</h4>
               <h4><b>{lang.ReytingTable.col3.row9}:</b> {data[0]?.wifi || ''}</h4>
               <h4><b>{lang.ReytingTable.col3.row10}:</b> {data[0]?.oxirgiUchYilKomp || ''}</h4>
            </div>

            <div className="reytingTable-body__button" >
               <h4><b>{lang.ReytingTable.col4.row1}:</b> {data[0]?.jamiOqituvchi || ''}</h4>
               <h4><b>{lang.ReytingTable.col4.row2}:</b> {data[0]?.oliyMalumotOqituvchi || ''}</h4>
               <h4><b>{lang.ReytingTable.col4.row3}:</b> {data[0]?.ortaMaxsus || ''}</h4>
               <h4><b>{lang.ReytingTable.col4.row4}:</b> {data[0]?.oliyToifaOqituvchi || ''}</h4>
               <h4><b>{lang.ReytingTable.col4.row5}:</b> {data[0]?.birinchiToifaOqituvchi || ''}</h4>
               <h4><b>{lang.ReytingTable.col4.row6}:</b> </h4>
               <h4><b>{lang.ReytingTable.col4.row7}:</b> {data[0]?.sertifikatliOqituvchi || ''}</h4>
            </div>

            <div className="reytingTable-body__button">
               <h4><b>{lang.ReytingTable.col5.row1}:</b> </h4>
               <h4><b>{lang.ReytingTable.col5.row2}:</b> {data[0]?.isitishTzimi || ''}</h4>
               <h4><b>{lang.ReytingTable.col5.row3}:</b> {data[0]?.yongingaQarshi || ''}</h4>
               <h4><b>{lang.ReytingTable.col5.row4}:</b> {data[0]?.ichimlikSuvi || ''}</h4>
               <h4><b>{lang.ReytingTable.col5.row5}:</b> {data[0]?.hojatxona || ''}</h4>
               <h4><b>{lang.ReytingTable.col5.row6}:</b> {data[0]?.kitoblarSoni || ''}</h4>
               <h4><b>{lang.ReytingTable.col5.row7}:</b> {data[0]?.faollarzali || ''}</h4>
               <h4><b>{lang.ReytingTable.col5.row8}:</b> </h4>
               <h4><b>{lang.ReytingTable.col5.row9}:</b> {data[0]?.maxsusSinfXonalar || ''}</h4>
            </div>
         </div>
      </div>
   )
}

export default ReytingTable